import "./src/styles/global.css";

import { parse, stringify } from "qs";
import { navigate } from "gatsby";

// Update next route with booking code
export const onRouteUpdate = ({ location, prevLocation }) => {
  const { booking_code } = parse(prevLocation?.search, {
    ignoreQueryPrefix: true,
  });

  // For some reason gatsby reach router is adding key with Date.now() on every call
  // https://github.com/gatsbyjs/reach-router/blob/71cf00357c9f7305d4aefa09658512c8fe6f691a/src/lib/history.js#L74

  // And gatsby checks key in location state to say if route has changed
  // https://github.com/gatsbyjs/gatsby/blob/9a32590d31a32af0e681016ef3d358ce82b98ee2/packages/gatsby/cache-dir/navigation.js#L209

  // This causes infinite loop, so here we manually check for only pathname
  const isNotSamePath = location.pathname !== prevLocation?.pathname;
  const navigateWithBookingCodeFromLastUrl = booking_code && isNotSamePath;
  if (navigateWithBookingCodeFromLastUrl) {
    const parsedQs = parse(location.search, { ignoreQueryPrefix: true });

    navigate(
      `${location.pathname}${stringify(
        { ...parsedQs, booking_code },
        { addQueryPrefix: true }
      )}`,
      { replace: true }
    );
  }
};

export const shouldUpdateScroll = ({ routerProps }) => {
  const { disableScrollUpdate } = routerProps.location.state || {};
  return !disableScrollUpdate;
};
