exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-templates-default-tsx": () => import("./../../../src/templates/default.tsx" /* webpackChunkName: "component---src-templates-default-tsx" */),
  "component---src-templates-offer-tsx": () => import("./../../../src/templates/offer.tsx" /* webpackChunkName: "component---src-templates-offer-tsx" */),
  "component---src-templates-room-tsx": () => import("./../../../src/templates/room.tsx" /* webpackChunkName: "component---src-templates-room-tsx" */),
  "slice---src-components-footer-tsx": () => import("./../../../src/components/Footer.tsx" /* webpackChunkName: "slice---src-components-footer-tsx" */),
  "slice---src-components-navbar-tsx": () => import("./../../../src/components/Navbar.tsx" /* webpackChunkName: "slice---src-components-navbar-tsx" */)
}

